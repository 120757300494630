import { UncontrolledTooltip } from "reactstrap";
import "./CheckMarkIcon.scss";

export const CheckMarkIcon = (props: {
  tooltipMessage: string;
  className?: string;
  iconId?: string;
}) => {
  return (
    <div className="checkmark-icon-div">
      <span
        id={props.iconId ? `${props.iconId}` : "checkmark-icon"}
        className={`checkmark-icon-container ${props.className || ""}`}
      >
        <i className="fa fa-solid fa-check-circle fa-sm blue-checkmark" />
      </span>
      <UncontrolledTooltip
        placement="top"
        target={props.iconId ? `${props.iconId}` : "checkmark-icon"}
        trigger="hover"
      >
        {props.tooltipMessage}
      </UncontrolledTooltip>
    </div>
  );
};
