import { RequestType } from "../../../../../components/withAPI";
import { ITableSettings, QueryInput } from "../../SetTable";
import { DataType } from "../../../../../models";
import { ToastrError } from "../../../../../services/toastrService";
import { CallAPIFunction } from "../../../../../components/useAPI";
import { isDataTypeNumeric } from "src/utility/metadata/isDataTypeNumeric";

export const getPreviewRows = async (
  connectionName: string,
  callAPI: CallAPIFunction,
  tableSettings: ITableSettings,
  queryInputs?: QueryInput[],
) => {
  let query = `SELECT * FROM [${connectionName}].[REST].[${tableSettings.tableName}]`;
  const parameters: {
    [key: string]: {
      dataType: DataType | null | undefined;
      value: string | number | boolean;
    };
  } = {};
  if (queryInputs && queryInputs.length > 0 && tableSettings.useQuerySlicer) {
    const whereClauses = queryInputs.map((input, index) => {
      const paramName = `@p${index + 1}`;
      let column;
      column = tableSettings.columns.find(
        (col) => col.columnName === input.key,
      );
      if (!column) {
        column = tableSettings.pseudoColumns?.find(
          (col) => col.pseudoColumnName === input.key,
        );
      }

      let parsedValue: string | number | boolean = input.value;

      // If the column is numeric, parse the input value as a number if possible.
      // If the user enters something that is not a valid number just treat it as a string.
      if (
        column != null &&
        column.dataType != null &&
        isDataTypeNumeric(column.dataType)
      ) {
        if (!isNaN(Number(input.value))) {
          parsedValue = Number(input.value);
        }
      }

      if (column?.dataType === DataType.BOOLEAN) {
        parsedValue =
          parsedValue === 1 ||
          input.value.toLowerCase() === "true" ||
          input.value.toLowerCase() === "yes";
      }

      parameters[paramName] = {
        dataType: column?.dataType,
        value: parsedValue,
      };

      return `[${input.key}] = ${paramName}`;
    });
    const whereClause = whereClauses.join(" AND ");
    query += ` WHERE ${whereClause} LIMIT 10`;
  } else {
    query += ` LIMIT 10`;
  }

  const postData = {
    query: query,
    defaultCatalog: connectionName,
    parameters: parameters,
  };

  const { status, payload } = await callAPI(
    RequestType.Post,
    "/query",
    "Failed to generate preview table",
    JSON.stringify(postData),
    undefined,
    undefined,
  );
  if (status === 200) {
    if (payload.error) {
      ToastrError("Failed to generate preview table", payload.error.message);
      return;
    }
    //All the rows and schema would be in payload.results[0]
    const schema = payload.results[0].schema;
    const rows = payload.results[0].rows;
    const allColumnNamesArray = schema.map(
      (x: { columnLabel: any }) => x.columnLabel,
    );
    const resultArray = [];

    for (let i = 0; i < rows.length; i++) {
      const row: any = {};
      for (let j = 0; j < allColumnNamesArray.length; j++) {
        const value = rows[i][j];
        row[allColumnNamesArray[j]] = value;
      }
      resultArray.push(row);
    }
    return resultArray;
  }
};
