import React, { useContext, useMemo, useState } from "react";

// TODO: Curse whoever did this for planting the seed in my head
import {
  Col,
  Row,
  Card,
  Button,
  Spinner,
  CardBody,
  Container,
} from "reactstrap";

import { IModalProps } from "../../../../components/CDataModal";
import { IAPI, RequestType, withAPI } from "../../../../components/withAPI";
import {
  withRouter,
  ComponentWithRouterProps,
} from "../../../../components/withRouter";
import { APITableResponseType, IAPITable } from "../../../../models";
import { mapConnectionSubmissionData } from "../../ApiConnectorFactory";
import { IGlobalSettings } from "../../ApiConnector";
import { IFullPermission } from "../../../connections/components/PermissionsCard";
import { ModalContext } from "../../../../routes/ModalContext";
import { ColumnDef, RowSelectionState } from "@tanstack/react-table";
import ListTable from "../../../../components/tables/ListTable";
import { SearchInput } from "../../../../components/tables/components/SearchInput";
import { ITableSettings } from "../../SetTable/SetTable";
import { useAppSelector } from "src/redux/hooks";

interface ITablesTabProps extends IAPI, ComponentWithRouterProps {
  globalSettings: IGlobalSettings;
  permissions: IFullPermission[];
  tables: IAPITable[];
  setTables: (tables: IAPITable[]) => void;
  setTableSettings: (tableSettings: ITableSettings) => void;
  openSetTablePage: (name: string) => void;
  setUnsavedGlobalChanges: (unsavedGlobalChanges: boolean) => void;
}

interface IAPITableRow {
  id: string;
  name: string;
  table: IAPITable;
  responseType: string;
  requestURL: string;
  editButtons: JSX.Element;
}

const TablesTab = (props: ITablesTabProps) => {
  const [selectedTables, setSelectedTables] = useState<RowSelectionState>({});
  const [filterText, setFilterText] = useState<string>("");
  const [processingRequest, setProcessingRequest] = useState(false);

  const driverList = useAppSelector((state) => state.driversList);

  const modalContext = useContext(ModalContext);

  function openDeleteTableModal(event: React.MouseEvent, tableName?: string) {
    event.preventDefault();
    event.stopPropagation();

    const names = tableName ? [tableName] : Object.keys(selectedTables);

    const tableCount = names.length > 1 ? `${names.length} tables` : "a table";
    const tableRemoval =
      names.length > 1
        ? "these tables to OData, they will also be removed"
        : "this table to Odata, it will also be removed";
    const message = `You are about to delete ${tableCount}. If you have added ${tableRemoval}. Are you sure you want to proceed?`;

    const confirmButton = (
      <Button
        type="button"
        color="danger"
        onClick={(event: any) => onDeleteTables(event, names)}
      >
        <div className="icon no-pointer-event" />
        Delete
      </Button>
    );
    const modal = {
      title: "Delete Table",
      body: <div>{message}</div>,
      primaryButton: confirmButton,
      secondaryButton: (
        <Button color="secondary" onClick={modalContext.toggleModal}>
          Close
        </Button>
      ),
      displayToggleCloseButton: true,
      displayed: true,
    } as IModalProps;
    modalContext.setModal(modal);
  }

  async function onDeleteTables(event: any, tableNamesToDelete: string[]) {
    event.preventDefault();
    const tablesClone = [...props.tables];
    const filteredTables = tablesClone.filter(
      (table: IAPITable) =>
        !tableNamesToDelete.find((name: string) => name === table.name),
    );
    await saveApiConnection(filteredTables);
  }

  async function saveApiConnection(filteredTables: IAPITable[]) {
    const data = mapConnectionSubmissionData(
      props.globalSettings,
      filteredTables,
      props.permissions,
      driverList,
    );
    setProcessingRequest(true);

    const { status } = await props.callAPI(
      RequestType.Put,
      "/account/updateConnection",
      "Failed to delete table(s)",
      data,
    );
    if (status === 200) {
      props.setTables(filteredTables);
      handlePostDeleteTasks();
    }
    modalContext.toggleModal();
    setProcessingRequest(false);
  }

  function handlePostDeleteTasks() {
    setSelectedTables({});
    props.setUnsavedGlobalChanges(false);
  }

  function openPreviewTableModal() {
    alert("previewTableModal");
  }

  let tableListBody: any = [];

  if (props.tables && props.tables.length !== 0) {
    tableListBody = props.tables.map((table: any) => {
      return {
        id: table.name,
        name: table.name,
        table: table,
        responseType:
          table.responseType === APITableResponseType.JSON ? "JSON" : "XML",
        requestURL: table.requestUrl,
        editButtons: (
          <>
            {
              /*As per cloud-6056, this preview button will be hidden even after MVP.*/
              false && (
                <button
                  onClick={() => openPreviewTableModal()}
                  className="table-button"
                  aria-label="preview table modal"
                  data-testid="button-preview-table-modal"
                >
                  <i className="fa fa-regular fa-eye align-middle"></i>
                </button>
              )
            }

            <button
              onClick={() => props.openSetTablePage(table.name)}
              className="table-button px-0"
              aria-label="open edit table page"
              data-testid="link-table-edit"
            >
              <i className="fa-regular fa-pen align-middle"></i>
            </button>
            <button
              onClick={(event) => openDeleteTableModal(event, table.name)}
              className="table-button"
              aria-label="delete table modal"
              data-testid="button-delete-table-modal"
            >
              <i className="fa-regular fa-xmark fa-lg align-middle"></i>
            </button>
          </>
        ),
      };
    });
  }

  const columns = useMemo<ColumnDef<IAPITableRow>[]>(
    () => [
      {
        accessorKey: "name",
        id: "name",
        enableSorting: true,
        header: () => <span>Table</span>,
        meta: {
          className: "name-column",
        },
        cell: ({ row }) => {
          return <>{row.original.name}</>;
        },
      },
      {
        accessorKey: "responseType",
        id: "responseType",
        enableSorting: true,
        header: () => <span>Response Type</span>,
        meta: {
          className: "response-type-column",
        },
        cell: ({ row }) => {
          return <>{row.original.responseType}</>;
        },
      },
      {
        accessorKey: "requestURL",
        id: "requestURL",
        enableSorting: true,
        header: () => <span>Request URL</span>,
        meta: {
          className: "request-url-column",
        },
        cell: ({ row }) => {
          return <>{row.original.requestURL}</>;
        },
      },
      {
        accessorKey: "editButtons",
        id: "editButtons",
        enableSorting: false,
        header: () => <></>,
        meta: {
          className: "buttons-column",
        },
        cell: ({ row }) => {
          return row.original.editButtons;
        },
      },
    ],
    [],
  );

  return (
    <Container fluid className="api-connector-tables-tab">
      <div hidden={!processingRequest}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
      <div>
        <Card>
          <CardBody>
            <Row>
              <Col className="search-column">
                <SearchInput
                  value={filterText}
                  setValue={setFilterText}
                  searchPlaceholder="Search tables..."
                  className="tables-tab-search"
                />
              </Col>
              <Col className="action-buttons-column text-end ps-0">
                <span>
                  <Button
                    type="button"
                    disabled={Object.keys(selectedTables).length < 1}
                    color="secondary"
                    className="me-1 card-actions"
                    onClick={(event: React.MouseEvent) =>
                      openDeleteTableModal(event)
                    }
                  >
                    <i className="fa fa-times icon no-pointer-event" />
                    Delete
                  </Button>
                  <Button
                    onClick={() => props.openSetTablePage("")}
                    color="primary"
                  >
                    <i className="fa fa-plus no-pointer-event" />
                    Add
                  </Button>
                </span>
              </Col>
            </Row>
            <div className="mt-2">
              <ListTable
                columns={columns}
                data={tableListBody}
                emptyTableMessage="Click the Add button to add tables to your API Connector."
                enableFiltering={false}
                enablePagination={true}
                enableCheckboxes={true}
                onRowClick={(row: IAPITableRow) =>
                  props.openSetTablePage(row.name)
                }
                globalSearchValue={filterText}
                selection={selectedTables}
                setSelection={setSelectedTables}
              />
            </div>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

export default withAPI(withRouter(TablesTab));
