/* eslint-disable max-lines */
import { useEffect, useRef, useState } from "react";
import {
  NavLink as RouterNavLink,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { Col, Container, Row, Spinner } from "reactstrap";

import {
  IAPIAuth,
  IAPIAdvanced,
  IAPIPagination,
  IAPITable,
  IConnection,
  APIAuthType,
  UserRole,
} from "../../models";

import PermissionsTab from "./GlobalSettings/PermissionsTab/PermissionsTab";
import TablesTab from "./GlobalSettings/TablesTab/TablesTab";
import GlobalSettingsTab from "./GlobalSettings/GlobalSettingsTab/GlobalSettingsTab";
import { RequestType } from "../../components/withAPI";
import SetTable from "./SetTable/SetTable";
import Loader from "../../components/Loader";
import PromptWithModal from "../../components/PromptWithModal";
import TitleBadge from "../../components/TitleBadge";
import { ToastrError, ToastrSuccess } from "../../services/toastrService";
import { mapConnectionSubmissionData } from "./ApiConnectorFactory";
import { initialGlobalSettings } from "./ApiConnector.mock";
import { createDeepCopy } from "../../utility/CreateDeepCopy";
import { generateDefaultNumberedName } from "../../utility/generateDefaultNumberedName";
import { useAPI } from "../../components/useAPI";
import ApiConnectorSaveButton from "./GlobalSettings/components/ApiConnectorSaveButton";
import TabWrapper from "../../components/TabWrapper";
import { useConnectionSetup } from "../../hooks/useConnectionSetup";
import { IConnectionWithCachingInfo } from "../../bffmodels/IConnectionWithCachingInfo";
import { RefreshMetadataButton } from "../connections/components/RefreshMetadataButton";
import { ButtonType, CDataButton } from "../../components/buttons/CDataButton";
import { DeleteConnectionModal } from "../connections/components/modals/DeleteConnectionModal";
import { ExploreDataButton } from "../../components/buttons/ExploreDataButton";
import { ViewDocumentationButton } from "../../components/buttons/ViewDocumentationButton";
import { setBaseDocsAddress } from "../../utility/FlyoutDocsFactory";
import { showFlyout } from "../../redux/actions";
import { Flyout } from "../../components/Flyout";
import { IFullPermission } from "../connections/components/PermissionsCard";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useIsConnectForSpreadsheets } from "../../hooks/useIsConnectForSpreadsheets";
import { useNameAvailabilityCheck } from "../../hooks/useNameAvailabilityCheck";
import { isEmpty } from "lodash";
import { getSalesEmailAddress } from "../../utility/LocalizedEmailAddresses";
import { IConnectionArgs } from "src/bffmodels/Connection/IConnectionArgs";

export enum ApiConnectorTabs {
  GlobalSettings = "GlobalSettings",
  Tables = "Tables",
  Permissions = "Permissions",
}

export enum ApiConnectionPageType {
  AddConnection = 0,
  EditConnection = 1,
}

export type KeyValuePair = {
  [key: string]: any;
};

export type KeyValueTableRow = {
  name: string;
  value: string;
};

export interface IGlobalSettings {
  connectionID: string;
  connectionName: string;
  authentication: IAPIAuth;
  advanced: IAPIAdvanced;
  headers: KeyValueTableRow[];
  pagination: IAPIPagination;
  queryParams: KeyValueTableRow[];
  oAuthButtonInvalid?: boolean;
}

export function mapKeyValuePairsToTableRows(keyValuePairs: KeyValuePair) {
  return Object.entries(keyValuePairs).map(([key, value]) => {
    const header: KeyValueTableRow = {
      name: key,
      value: value,
    };
    return header;
  });
}

const ApiConnector = () => {
  const user = useAppSelector((state) => state.user);
  const driverList = useAppSelector((state) => state.driversList);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const locationState = location.state as IAPIConnectorState | null;
  const navigate = useNavigate();
  const isSpreadsheetUser = useIsConnectForSpreadsheets();

  const initialAPIConnectorPageType =
    locationState?.apiConnectionPageType ?? ApiConnectionPageType.AddConnection;
  const selectedTable = checkForTableURLParam();

  const [apiConnectionPageType, setApiConnectionPageType] =
    useState<ApiConnectionPageType>(initialAPIConnectorPageType);
  const [activeTab, setActiveTab] = useState(ApiConnectorTabs.GlobalSettings);
  const [globalSettings, setGlobalSettings] = useState<IGlobalSettings>(
    getDefaultGlobalSettings(createDeepCopy(initialGlobalSettings)),
  );
  const [tables, setTables] = useState<IAPITable[]>([]);
  const [cacheJobs, setCacheJobs] = useState<string[]>([]);
  const [permissions, setPermissions] = useState<IFullPermission[]>([]);
  const [selectedTableName, setSelectedTableName] =
    useState<string>(selectedTable);
  const [isSetTablePageDisplayed, setIsSetTablePageDisplayed] = useState(
    selectedTable !== "",
  );
  const [unsavedGlobalChanges, setUnsavedGlobalChanges] = useState<boolean>(
    initialAPIConnectorPageType === ApiConnectionPageType.AddConnection,
  );
  const [processingRequest, setProcessingRequest] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [unavailableNames, setUnavailableNames] = useState<string[]>([]);

  const { getRestrictedNames } = useNameAvailabilityCheck();

  const api = useAPI();
  const connectionNameRef = useRef<any>();

  function getComponentName() {
    return "pages-connections-ApiConnector";
  }

  useEffect(() => {
    async function initializeAsync() {
      let connectionName = ""; // Used for fetching restricted names later

      if (apiConnectionPageType === ApiConnectionPageType.EditConnection) {
        const connectionProperties: IConnectionWithCachingInfo =
          await getConnectionProperties();
        const connection = connectionProperties.connection;
        const existingGlobalSettings = mapGlobalSettings(connection);

        connectionName = connection.name;
        setGlobalSettings(existingGlobalSettings);
        setTables(connection.apiConnectorProps?.tables ?? []);
        setCacheJobs(connectionProperties.cacheJobIds);
      }

      const restrictedNames = await getRestrictedNamesAsync(connectionName);
      setUnavailableNames(restrictedNames ?? []);
      setLoading(false);
    }

    initializeAsync();
  }, []); // eslint-disable-line

  function checkForTableURLParam() {
    const params = new URLSearchParams(window.location.search);
    const paramValue = params.get("table") as string;
    return paramValue ?? "";
  }

  useEffect(() => {
    isSetTablePageDisplayed && selectedTableName
      ? window.history.replaceState(
          window.history.state,
          "",
          `/apiconnector?table=${selectedTableName}`,
        )
      : window.history.replaceState(window.history.state, "", "/apiconnector");
  }, [isSetTablePageDisplayed, selectedTableName]);

  const useConnectionMethods = useConnectionSetup(null);

  useEffect(() => {
    async function asyncMethod() {
      const connectionId = locationState?.connectionId;
      const permissions = await useConnectionMethods.initializeUserPermissions(
        "APIConnector",
        connectionId,
      );
      setPermissions(permissions as IFullPermission[]);
    }

    asyncMethod();
  }, []); // eslint-disable-line

  async function getConnectionProperties() {
    const connectionId = locationState?.connectionId;
    const { status, payload } = await api.callAPI(
      RequestType.Get,
      `account/connections/${connectionId}`,
      "Failed to get connection properties due to the following error:",
    );
    if (status === 200) {
      return payload;
    }
  }

  function mapGlobalSettings(connection: IConnection) {
    const existingGlobalSettings: IGlobalSettings = {
      connectionID: connection.id!,
      connectionName: connection.name!,
      authentication: connection.apiConnectorProps!.authentication!,
      advanced: connection.apiConnectorProps!.advanced!,
      // TODO: CLOUD-5640 - See if we can update KeyValueTable to use headers directly
      headers: mapKeyValuePairsToTableRows(
        connection.apiConnectorProps!.headers!,
      ),
      pagination: connection!.apiConnectorProps!.pagination!,
      queryParams: mapKeyValuePairsToTableRows(
        connection!.apiConnectorProps!.queryParams!,
      ),
    };
    return existingGlobalSettings;
  }

  async function getRestrictedNamesAsync(excludedName: string) {
    const names = await getRestrictedNames({
      existingConnections: locationState?.connections,
      excludedName: excludedName,
    });
    return names;
  }

  function getDefaultGlobalSettings(globalSetting: IGlobalSettings) {
    const existingConnections = locationState?.connections;
    const defaultConnectionName = generateDefaultNumberedName(
      "API",
      existingConnections,
    );
    const newGlobalSettings = { ...globalSetting };
    newGlobalSettings.connectionName = defaultConnectionName;
    return newGlobalSettings;
  }

  function openSetTablePage(tableName?: string) {
    setSelectedTableName(tableName || "");
    setIsSetTablePageDisplayed(true);
  }
  async function handleValidSubmit() {
    setProcessingRequest(true);

    if (
      (globalSettings.authentication.type === APIAuthType.OAuth1 ||
        globalSettings.authentication.type === APIAuthType.OAuth2) &&
      !globalSettings.authentication.oAuthAccessToken
    ) {
      setGlobalSettings((prevGlobalSettings: IGlobalSettings) => {
        const newGlobalSettings = { ...prevGlobalSettings };
        newGlobalSettings.oAuthButtonInvalid = true;
        return newGlobalSettings;
      });
      ToastrError(
        "Test Connection Failed!",
        `Click the Sign-In button below first in order to authenticate to API Connector.`,
      );
      setProcessingRequest(false);
      return;
    }

    // Check for name validation errors; if any are present, show an error and abort submission.
    if (!isEmpty(connectionNameRef.current?.errors)) {
      setProcessingRequest(false);
      ToastrError(
        "Connection Name Error",
        "Please correct the issues with your connection name and try again.",
      );
      return;
    }

    //if submitForm returns true, means validation has an error hence don't proceed for update conneciton call.
    const response: boolean = await connectionNameRef.current?.submitForm();
    if (response) {
      setProcessingRequest(false);
      return;
    }

    if (apiConnectionPageType === ApiConnectionPageType.AddConnection)
      createNewConnection();
    else updateExistingConnection();
  }

  function updateOAuthRefreshURL(data: IConnectionArgs) {
    const connectionSubmissionData = createDeepCopy(data);
    const authenticationSettings =
      connectionSubmissionData.apiConnectorProps?.authentication;

    //if OAuth Refresh URL is empty for OAuth 2.0, use OAuth Access Token URL.
    if (
      authenticationSettings?.type === APIAuthType.OAuth2 &&
      !authenticationSettings.oAuthRefreshTokenUrl
    ) {
      connectionSubmissionData!.apiConnectorProps!.authentication!.oAuthRefreshTokenUrl =
        connectionSubmissionData?.apiConnectorProps?.authentication?.oAuthAccessTokenUrl;
    }

    return connectionSubmissionData;
  }

  async function createNewConnection() {
    let data = mapConnectionSubmissionData(
      globalSettings,
      tables ?? [],
      permissions,
      driverList,
    );

    data = updateOAuthRefreshURL(data);

    const { status, payload } = await api.callAPI(
      RequestType.Post,
      "/account/connections",
      "Failed to create new API connection.",
      data,
    );
    if (status === 200) {
      handlePostCreationTasks(payload.connection.id);
    }
    setProcessingRequest(false);
  }

  async function handlePostCreationTasks(connectionId: string) {
    ToastrSuccess(
      "API connection successfully created",
      `Your API connection, ${globalSettings.connectionName}, was successfully created.`,
    );
    setUnsavedGlobalChanges(false);
    toggleTab(ApiConnectorTabs.Tables);
    setApiConnectionPageType(ApiConnectionPageType.EditConnection);

    setGlobalSettings((prevGlobalSettings: IGlobalSettings) => {
      const newGlobalSettings = { ...prevGlobalSettings };
      newGlobalSettings.connectionID = connectionId;
      return newGlobalSettings;
    });
  }

  // Once the connection has been saved, we need to update the browser state to store the
  // new connection ID and that we are now in update mode.
  // This is in a useEffect since the component needs to re-render before we navigate, otherwise
  // this triggers the `handleBlockedNavigation` call that shows a modal saying you have unsaved changes.
  useEffect(() => {
    if (
      globalSettings?.connectionID != null &&
      globalSettings.connectionID.length > 0 &&
      locationState?.connectionId == null
    ) {
      navigate("/apiconnector", {
        replace: true,
        state: {
          ...locationState,
          connectionId: globalSettings?.connectionID,
          apiConnectionPageType: ApiConnectionPageType.EditConnection,
        },
      });
    }
  }, [globalSettings?.connectionID]); // eslint-disable-line

  async function updateExistingConnection() {
    let data = mapConnectionSubmissionData(
      globalSettings,
      tables ?? [],
      permissions,
      driverList,
    );

    data = updateOAuthRefreshURL(data);

    const { status } = await api.callAPI(
      RequestType.Put,
      "/account/updateConnection",
      "Failed to update API connection",
      data,
    );
    if (status === 200) {
      ToastrSuccess(
        "API connection successfully saved",
        `Your API connection, ${globalSettings.connectionName}, was successfully updated.`,
      );
      setUnsavedGlobalChanges(false);
    }
    setProcessingRequest(false);
  }

  function toggleTab(tab: ApiConnectorTabs) {
    if (!tab) setActiveTab(ApiConnectorTabs.GlobalSettings);
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const tabsConfig = [
    {
      tabName: "Global Settings",
      tabEnum: ApiConnectorTabs.GlobalSettings,
      tabComponent: (
        <GlobalSettingsTab
          globalSettings={globalSettings}
          setGlobalSettings={setGlobalSettings}
          unsavedGlobalChanges={unsavedGlobalChanges}
          setUnsavedGlobalChanges={setUnsavedGlobalChanges}
          existingNames={unavailableNames}
          apiConnectionPageType={apiConnectionPageType}
          ref={connectionNameRef}
        />
      ),
      isVisible: true,
    },
    {
      tabName: "Tables",
      tabEnum: ApiConnectorTabs.Tables,
      tabComponent: (
        <TablesTab
          globalSettings={globalSettings}
          permissions={permissions}
          tables={tables}
          setTables={setTables}
          openSetTablePage={openSetTablePage}
          setUnsavedGlobalChanges={setUnsavedGlobalChanges}
        />
      ),
      isVisible: true,
      isDisabled: unsavedGlobalChanges,
      disabledTooltip:
        apiConnectionPageType === ApiConnectionPageType.AddConnection
          ? "Create your Global Settings first before proceeding."
          : "Resolve and save all changes below before proceeding.",
    },
    {
      tabName: "Permissions",
      tabEnum: ApiConnectorTabs.Permissions,
      tabComponent: (
        <PermissionsTab
          permissions={permissions}
          setPermissions={setPermissions}
        />
      ),
      isVisible: !isSpreadsheetUser,
      isDisabled: unsavedGlobalChanges,
      disabledTooltip:
        apiConnectionPageType === ApiConnectionPageType.AddConnection
          ? "Create your Global Settings first before proceeding."
          : "Resolve and save all changes below before proceeding.",
    },
  ];

  const handleOpenDocumentationFlyout = () => {
    const docUrl = `${setBaseDocsAddress()}APIConnector.html`;
    const name = "API";
    const source = "Connection";

    dispatch(showFlyout(docUrl, name, source));
  };

  function renderApiConnector() {
    const apiConnectorPage = (
      <Container fluid className={`p-0 ${getComponentName()}`}>
        <Row>
          <Col>
            <div className="apiConnector-headerContainer">
              <RouterNavLink
                className="align-middle text-dark"
                to={{
                  pathname: "/connections",
                }}
              >
                <i className="fa fa-arrow-left fa-lg back-arrow" />
              </RouterNavLink>
              <h1 className="apiConnector-header h3 m-0">
                {apiConnectionPageType === ApiConnectionPageType.EditConnection
                  ? "Edit "
                  : "Add "}
                API Connection
                <TitleBadge
                  name={"Beta"}
                  tooltip={
                    <>
                      This connection is currently in beta. If you require
                      assistance or have feedback, please contact{" "}
                      <a
                        href={`mailto:${getSalesEmailAddress()}`}
                        className="support-hyperlink"
                      >
                        {getSalesEmailAddress()}
                      </a>
                      .
                    </>
                  }
                />
              </h1>
              {activeTab !== ApiConnectorTabs.Tables ? (
                <div className="apiConnector-topButtonsContainer">
                  {/* Refresh Metadata button */}
                  {apiConnectionPageType ===
                    ApiConnectionPageType.EditConnection && (
                    <RefreshMetadataButton
                      isInDropdown={false}
                      connectionId={globalSettings.connectionID}
                      connectionName={globalSettings.connectionName}
                    />
                  )}

                  {/* Delete button */}
                  {user.role === UserRole.Admin &&
                    apiConnectionPageType ===
                      ApiConnectionPageType.EditConnection && (
                      <CDataButton
                        buttonType={ButtonType.Secondary}
                        onClick={() => {
                          setIsDeleteModalOpen(true);
                        }}
                      >
                        <i className="fa fa-times align-middle me-2" />
                        Delete
                      </CDataButton>
                    )}

                  {/* Save Changes button */}
                  <ApiConnectorSaveButton
                    cacheJobs={cacheJobs}
                    handleValidSubmit={handleValidSubmit}
                  />
                </div>
              ) : null}
            </div>

            <TabWrapper
              tabs={tabsConfig}
              currentTab={activeTab}
              setCurrentTab={(tab) => toggleTab(tab as ApiConnectorTabs)}
              extraButton={
                <div className="apiConnector-exploreButtonContainer">
                  <ExploreDataButton
                    isDisabled={
                      apiConnectionPageType ===
                      ApiConnectionPageType.AddConnection
                    }
                  />
                  <ViewDocumentationButton
                    openFlyout={handleOpenDocumentationFlyout}
                  />
                </div>
              }
              mountWhenDisabled={true}
            />
          </Col>

          <Flyout />
        </Row>
      </Container>
    );

    const setTablePage = isSetTablePageDisplayed && (
      <SetTable
        globalSettings={globalSettings}
        setGlobalSettings={setGlobalSettings}
        unsavedGlobalChanges={unsavedGlobalChanges}
        setUnsavedGlobalChanges={setUnsavedGlobalChanges}
        tables={tables}
        setTables={setTables}
        permissions={permissions}
        selectedTableName={selectedTableName}
        setSelectedTableName={setSelectedTableName}
        setIsSetTablePageDisplayed={setIsSetTablePageDisplayed}
        apiConnectionPageType={apiConnectionPageType}
      />
    );

    return isSetTablePageDisplayed ? setTablePage : apiConnectorPage;
  }

  const contents = loading ? <Loader /> : renderApiConnector();

  return (
    <Container fluid className={"p-0 " + getComponentName()}>
      <div hidden={!processingRequest}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
      <PromptWithModal
        when={unsavedGlobalChanges}
        navigate={(path) => navigate(path)}
      />
      <DeleteConnectionModal
        connection={{
          id: globalSettings.connectionID,
          name: globalSettings.connectionName,
          driver: "API",
        }}
        close={() => setIsDeleteModalOpen(false)}
        displayed={isDeleteModalOpen}
      />
      {contents}
    </Container>
  );
};

type IAPIConnectorState = {
  connectionId?: string;
  apiConnectionPageType?: ApiConnectionPageType;
  connections?: IConnection[];
};

export default ApiConnector;
