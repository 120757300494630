import { isEqual, orderBy } from "lodash";
import { ChangeEvent, ReactNode, useState } from "react";
import { Input } from "reactstrap";
import { IndeterminateCheckbox } from "./IndeterminateCheckbox";
import "./CheckboxTree.scss";

export interface ICheckboxTreeProps<T> {
  /** Title displayed at the top of the tree */
  title: ReactNode;
  /**
   * True to allow selecting/unselecting all checkboxes by clicking the title.
   * @default true
   */
  canSelectAll?: boolean;
  /**
   * True to allow collapsing parent nodes to hide their children.
   * @default true
   */
  canCollapse?: boolean;
  checkedValues: string[];
  setCheckedValues: (values: string[]) => void;
  rows: ICheckboxTreeRow<T>[];
}

export interface ICheckboxTreeRow<T> {
  data: T;
  getValue: (data: T) => string;
  childRows: ICheckboxTreeRow<T>[];
  /** Set to control the render instead of just displaying a string in the UI. */
  render?: (data: T) => ReactNode;
}

/**
 * A component that shows a tree of checkboxes for the user to select.
 */
export function CheckboxTree<T>(props: ICheckboxTreeProps<T>) {
  const {
    title,
    canSelectAll = true,
    canCollapse = true,
    checkedValues,
    setCheckedValues,
    rows,
  } = props;

  const allPossibleValues = getFlatRowValuesForCheckboxTree(rows);

  // We need to do a deep comparison of the arrays here to deal with the case of:
  // 1. A user checks a checkbox.
  // 2. The user does a search that filters out some checkboxes.
  // 3. The checked checkbox is no longer in the list of rows but is in the `checkedValues` array.
  const areAllSelected = isEqual(
    orderBy(checkedValues),
    orderBy(allPossibleValues),
  );

  return (
    <div className="CheckboxTree-root tree-table-container">
      <table className="tree-table">
        <thead>
          <tr>
            <th>
              <div className="CheckboxTree-cell">
                {canSelectAll && (
                  <IndeterminateCheckbox
                    type="checkbox"
                    aria-label={areAllSelected ? "Unselect all" : "Select all"}
                    indeterminate={checkedValues.length > 0 && !areAllSelected}
                    checked={areAllSelected}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const isChecked = event.target.checked;
                      if (isChecked) {
                        setCheckedValues(allPossibleValues);
                      } else {
                        setCheckedValues([]);
                      }
                    }}
                  />
                )}
                {title}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => {
            const rowValue = row.getValue(row.data);

            return (
              <CheckboxTreeRow
                key={rowValue}
                row={row}
                depth={0}
                canCollapse={canCollapse}
                checkedValues={checkedValues}
                setCheckedValues={setCheckedValues}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

interface ICheckboxTreeRowProps<T> {
  row: ICheckboxTreeRow<T>;
  depth: number;
  checkedValues: string[];
  setCheckedValues: (values: string[]) => void;
  canCollapse: boolean | undefined;
}

function CheckboxTreeRow<T>(props: ICheckboxTreeRowProps<T>) {
  const { row, depth, canCollapse, checkedValues, setCheckedValues } = props;

  const [isExpanded, setIsExpanded] = useState(true);

  const checkedValueSet = new Set(checkedValues);

  const rowValue = row.getValue(row.data);

  return (
    <>
      <tr className="CheckboxTree-row" key={rowValue}>
        <td>
          <div
            className="CheckboxTree-cell"
            style={{ paddingLeft: 20 * depth }}
          >
            {canCollapse && (
              <button
                className="expand-collapse-button"
                aria-label={
                  isExpanded ? `collapse ${rowValue}` : `expand ${rowValue}`
                }
                onClick={() => setIsExpanded(!isExpanded)}
                style={{
                  // Just hide the button if we can't expand so it still takes up space
                  // This makes all the checkboxes line up even if we don't render the chevron button.
                  visibility: row.childRows.length > 0 ? "visible" : "hidden",
                }}
              >
                {isExpanded ? (
                  <i className="fa fa-chevron-down" />
                ) : (
                  <i className="fa fa-chevron-right" />
                )}
              </button>
            )}

            <Input
              type="checkbox"
              aria-label={rowValue}
              checked={checkedValueSet.has(rowValue)}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const isChecked = event.target.checked;
                if (isChecked) {
                  const newValues = Array.from(
                    new Set([...checkedValues, rowValue]),
                  );
                  setCheckedValues(newValues);
                } else {
                  const uniqueValues = new Set(checkedValues);
                  uniqueValues.delete(rowValue);
                  setCheckedValues(Array.from(uniqueValues));
                }
              }}
            />

            {row.render && row.render(row.data)}
            {!row.render && <>{rowValue}</>}
          </div>
        </td>
      </tr>
      {isExpanded &&
        row.childRows.map((childRow) => {
          return (
            <CheckboxTreeRow
              key={childRow.getValue(childRow.data)}
              depth={depth + 1}
              row={childRow}
              canCollapse={canCollapse}
              checkedValues={checkedValues}
              setCheckedValues={setCheckedValues}
            />
          );
        })}
    </>
  );
}

/** Returns a flat array of all row values. This just returns the values for all nodes in the tree. */
export function getFlatRowValuesForCheckboxTree<T>(
  rows: ICheckboxTreeRow<T>[],
): string[] {
  const flatValues = [...rows.map((r) => r.getValue(r.data))];

  for (const row of rows) {
    flatValues.push(...getFlatRowValuesForCheckboxTree(row.childRows));
  }

  return Array.from(flatValues);
}
