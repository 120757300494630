/* eslint-disable max-lines */
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";

import {
  Button,
  Col,
  Container,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";

import { IModalProps } from "../../../components/CDataModal";
import { ToastrError, ToastrSuccess } from "../../../services/toastrService";
import {
  IAPI,
  IAPIResponse,
  RequestType,
  withAPI,
} from "../../../components/withAPI";
import { ConfigureTableCard } from "./components/ConfigureTableCard";
import { FiltersCard } from "./components/FiltersCard";
import {
  IAPIColumn,
  IAPIFilter,
  APITableRequestMethod,
  APITableResponseType,
  IAPITable,
  DataType,
} from "../../../models";
import { showFlyout, hideFlyout } from "../../../redux/actions/flyoutActions";
import GlobalSettingsFlyout from "../GlobalSettings/GlobalSettingsTab/GlobalSettingsFlyout";
import TableDataCard from "./components/TableDataCard";
import { TableHeadersCard } from "./components/TableHeadersCard";
import { ParametersCard } from "./components/ParametersCard";
import sharedParsingUtil from "./components/ParsingUtilities/sharedParsingUtil";
import {
  IGlobalSettings,
  KeyValueTableRow,
  mapKeyValuePairsToTableRows,
  ApiConnectionPageType,
} from "../ApiConnector";
import {
  withRouter,
  ComponentWithRouterProps,
} from "../../../components/withRouter";
import { emptyTable, emptyTableSettings } from "./SetTable.mock";
import { IFullPermission } from "../../connections/components/PermissionsCard";
import { mapTable } from "./SetTableFactory";
import { TablePageType } from "./components/TablePageType";
import Loader from "../../../components/Loader";
import { createDeepCopy } from "../../../utility/CreateDeepCopy";
import { generateDefaultNumberedName } from "../../../utility/generateDefaultNumberedName";
import { mapConnectionSubmissionData } from "../ApiConnectorFactory";
import PromptWithModal from "../../../components/PromptWithModal";
import { ModalContext } from "../../../routes/ModalContext";
import {
  IConfigureTableInitialValues,
  TABLE_COLUMN_NAME_REGEX,
} from "./SetTableValidationDictionary";
import { JsonValueType } from "./components/ParsingUtilities/JSON/parseJSONAsTreeTable";
import TertiaryButton from "../../../components/buttons/TertiaryButton";
import TabWrapper from "../../../components/TabWrapper";
import { setBaseDocsAddress } from "../../../utility/FlyoutDocsFactory";
import { Flyout } from "../../../components/Flyout";
import { ViewDocumentationButton } from "../../../components/buttons/ViewDocumentationButton";
import {
  getPerRowValue,
  hasPerRowRequestError,
  isPerRowRequest,
} from "./components/PerRowRequestHelper";
import { RootPathsStep } from "./components/ConfigurationWizard/RootPaths/RootPathsStep";
import { TableWizardStep } from "./types/TableWizardStep";
import { PreviewRequestStep } from "./components/ConfigurationWizard/PreviewRequest/PreviewRequestStep";
import { PreviewTableStep } from "./components/ConfigurationWizard/PreviewTable/PreviewTableStep";
import { AddColumnsStepV2 } from "./components/ConfigurationWizard/AddColumns/AddColumnsStepV2";
import { QueryInputIdModal } from "./components/QueryInputIdModal";
import { FormikProps } from "formik";
import { IAPIConnectorSettings } from "./types/IAPIConnectorSettings";
import { isEmpty } from "lodash";
import { scrollToFirstValidationError } from "../../../components/form/scrollToFirstValidationError";
import { IAPIPseudoColumn } from "../../../models/APIConnector/IAPIPseudoColumn";
import PostSavePreviewTable from "./components/PostSavePreviewTable";
import { StepWizardModal } from "../../../components/wizard/StepWizardModal";
import StepWizardStep from "../../../components/wizard/components/StepWizardStep";
import { TablePreviewStep } from "./types/TablePreviewStep";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

interface ISetTableProps extends IAPI, ComponentWithRouterProps {
  globalSettings: IGlobalSettings;
  setGlobalSettings: Dispatch<SetStateAction<IGlobalSettings>>;
  tables: IAPITable[];
  setTables: (tables: IAPITable[]) => void;
  permissions: IFullPermission[];
  selectedTableName: string;
  setSelectedTableName: (selectedTableName?: string) => void;
  setIsSetTablePageDisplayed: (isSetTablePageDisplayed: boolean) => void;
  apiConnectionPageType: ApiConnectionPageType;
  setUnsavedGlobalChanges: (unsavedGlobalChanges: boolean) => void;
}

export type RootPath = {
  rootPath: string;
};

export type Column = {
  columnName: string;
  /** The column name generated by the driver, this is required for the preview at the end of the wizard but otherwise unused. */
  driverColumnName?: string;
  path: string;
  dataType: DataType | null;
  primaryKey: boolean;
};

export type PseudoColumn = {
  pseudoColumnName: string;
  dataType: DataType;
  outputColumn: boolean;
  required: boolean;
};

export interface ITableSettings {
  tableName: string;
  /**
   * Used to determine if the user renamed a table and we have to update references
   * to the table in things like datasets.
   */
  previousTableName: string;
  responseType: APITableResponseType;
  requestMethod: APITableRequestMethod;
  requestUrl: string;
  requestBody: string;
  queryParams: KeyValueTableRow[];
  headers: KeyValueTableRow[];
  rootPaths: RootPath[];
  columns: Column[];
  pseudoColumns: PseudoColumn[];
  filters: IAPIFilter[];
  useQuerySlicer: boolean;
}

export type QueryInput = {
  key: string;
  value: string;
};

const SetTable = (props: ISetTableProps) => {
  // The table settings that are currently saved. Only updates when the page is saved.
  const [initialTableSettings, setInitialTableSettings] =
    useState<ITableSettings>(emptyTableSettings);
  // The current table settings, update as the user types.
  const [tableSettings, setTableSettings] =
    useState<ITableSettings>(emptyTableSettings);

  const [currentTab, setCurrentTab] = useState("1");

  const [tablePageType, setTablePageType] = useState<TablePageType>(
    props.selectedTableName && props.selectedTableName !== ""
      ? TablePageType.editTable
      : TablePageType.addTable,
  );
  const [rootPathSelectionWizard, setRootPathSelectionWizard] = useState<
    string[]
  >([]);
  const [columnSelectionWizard, setColumnSelectionWizard] = useState<Column[]>(
    [],
  );

  const [isTableSetupWizardOpen, setIsTableSetupWizardOpen] = useState(false);

  // Toggled when clicking the query preview button in the top right.
  const [isTablePreviewOpen, setIsTablePreviewOpen] = useState(false);

  const [unsavedTableChanges, setUnsavedTableChanges] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [processingRequest, setProcessingRequest] = useState<boolean>(false);
  const [isTableFirstLoad, setIsTableFirstLoad] = useState<boolean>(false);
  const [perRowColumnNotFound, setPerRowColumnNotFound] = useState<string[]>(
    [],
  );
  const [isGlobalFlyoutDisplayed, setGlobalFlyoutDisplayed] =
    useState<boolean>(false);
  const [axiosResult, setAxiosResult] = useState<IAPIResponse>({
    payload: "",
    status: 200,
  });
  // If the user sets the API url to something with curly brackets like `https://api.example.com/table/{id}`,
  // They are required to enter a string when going through the preview wizard.
  const [queryInputs, setQueryInputs] = useState<QueryInput[]>([]);
  // True if the user selected custom root paths with the switch.
  // Stored here so it persists across wizard steps.
  const [isCustomRootPaths, setIsCustomRootPaths] = useState(false);

  // Called when the warning banner is closed to hide it and override the showChangeSettingsWarning variable below.
  const [hideShowChangeSettingsWarning, setHideShowChangeSettingsWarning] =
    useState(false);
  // Becomes true when the user changes the Request URL, Response Type, etc and may have broken the Table Data section.
  // This displays an orange warning at the top of the page.
  const showChangeSettingsWarning = useMemo(() => {
    // If the user clicked the close button on the banner and has not saved yet, do not show the banner
    if (hideShowChangeSettingsWarning) {
      return false;
    }
    // Never show the warning before the page is saved.
    if (tablePageType === TablePageType.addTable) {
      return false;
    }

    if (
      initialTableSettings.requestUrl !== tableSettings.requestUrl ||
      initialTableSettings.requestMethod !== tableSettings.requestMethod ||
      initialTableSettings.responseType !== tableSettings.responseType ||
      initialTableSettings.requestBody !== tableSettings.requestBody
    ) {
      return true;
    }

    return false;
  }, [
    hideShowChangeSettingsWarning,
    tablePageType,
    initialTableSettings,
    tableSettings,
  ]);

  const configureTableRef = useRef(
    {} as FormikProps<IConfigureTableInitialValues>,
  );
  const tableDataRef = useRef<any>();
  const parameterRef = useRef({} as FormikProps<ITableSettings>);
  const filtersRef = useRef({} as FormikProps<ITableSettings>);
  const headersRef = useRef({} as FormikProps<ITableSettings>);

  const modalContext = useContext(ModalContext);
  const [chameleonId, setChameleonId] = useState("");
  const baseDocsAddress = setBaseDocsAddress();
  const dispatch = useAppDispatch();
  const flyout = useAppSelector((state) => state.flyout);
  const driverList = useAppSelector((state) => state.driversList);

  function getComponentName() {
    return "pages-apiConnector-SetTable-SetTable";
  }

  const apiSettings: IAPIConnectorSettings = {
    globalSettings: props.globalSettings,
    setGlobalSettings: props.setGlobalSettings,
    tableSettings: tableSettings,
    setTableSettings: setTableSettings,
    setUnsavedChanges: setUnsavedTableChanges,
  };

  useEffect(() => {
    const initialTable = getInitialTable();

    const myTableSettings = mapApiTableToTableSettings(initialTable);
    setInitialTableSettings(myTableSettings);
    setTableSettings(myTableSettings);

    setLoading(false);
    setIsTableFirstLoad(true);

    // Hide the flyout when the component unmounts
    return () => {
      dispatch(hideFlyout());
    };
  }, []); // eslint-disable-line

  function saveWizardChanges() {
    const apiTable = { ...tableSettings };
    const formattedRootPaths = rootPathSelectionWizard.map((el) => ({
      rootPath: el,
    }));
    const combinedRootPathArray =
      formattedRootPaths.length === 0
        ? [...apiTable.rootPaths]
        : [...formattedRootPaths];
    const uniqueRootPaths = [];
    const rootPathSet = new Set();

    for (const item of combinedRootPathArray) {
      if (!rootPathSet.has(item.rootPath)) {
        rootPathSet.add(item.rootPath);
        uniqueRootPaths.push(item);
      }
    }
    apiTable.rootPaths = uniqueRootPaths;

    const rawColumnsFromWizard = createDeepCopy(columnSelectionWizard);
    const mappedTypesForColumns = rawColumnsFromWizard.map((col: any) => {
      col.dataType =
        typeof col.dataType === "string" ? mapType(col.dataType) : col.dataType;
      const fallbackPath = col.path;
      let relPath = col.path;
      //prepend array name by using column name if column path starts with array index
      relPath = sharedParsingUtil.startsWithArrayIndex(relPath)
        ? "/" + col.columnName
        : relPath;
      col.path = relPath === "" ? fallbackPath : relPath;
      setUnsavedTableChanges(true);
      return col;
    });
    const combinedColumnArray =
      mappedTypesForColumns.length === 0
        ? [...tableSettings.columns]
        : [...mappedTypesForColumns];
    const uniquePaths = createDeepCopy(
      filterNonUniquePaths(combinedColumnArray),
    );

    let invalidColCounter = 1;

    const sanitizedColumns = uniquePaths.map((col) => {
      const colOnlyLegalChars = sharedParsingUtil.removeInvalidColNameChars(
        col.columnName,
      );
      //test against column name validation
      if (TABLE_COLUMN_NAME_REGEX.test(colOnlyLegalChars)) {
        col.columnName = colOnlyLegalChars;
      } else {
        col.columnName = "Column_" + invalidColCounter;
        invalidColCounter++;
      }
      return col;
    });
    const uniqueColumnNames =
      sharedParsingUtil.handleDuplicateColumnNames(sanitizedColumns);

    apiTable.columns = uniqueColumnNames;

    setTableSettings(apiTable);
    tableDataRef.current?.regenerateRootPathsFormik(uniqueRootPaths);
    tableDataRef.current?.regenerateColumnsFormik();
  }

  function getInitialTable() {
    let initialTable: IAPITable = createDeepCopy(emptyTable);
    if (props.selectedTableName && props.selectedTableName !== "") {
      const existingTable = props.tables.find(
        (table: IAPITable) => table.name === props.selectedTableName,
      );
      if (existingTable) {
        initialTable = createDeepCopy(existingTable);
        setTablePageType(TablePageType.editTable);
      } else {
        initialTable.name = props.selectedTableName;
        setTablePageType(TablePageType.addTable);
      }
    } else {
      initialTable.name = generateDefaultNumberedName("table", props.tables);
      props.setSelectedTableName(initialTable.name);
      setTablePageType(TablePageType.addTable);
    }

    return initialTable;
  }

  function mapApiTableToTableSettings(initialTable: IAPITable): ITableSettings {
    return {
      tableName: initialTable.name ?? "",
      previousTableName: initialTable.name ?? "",
      responseType: initialTable.responseType,
      requestMethod: initialTable.requestMethod,
      requestUrl: initialTable.requestUrl ?? "",
      requestBody: initialTable.requestBody ?? "",
      queryParams: mapKeyValuePairsToTableRows(initialTable.queryParams ?? {}),
      headers: mapKeyValuePairsToTableRows(initialTable.headers ?? {}),
      rootPaths: (initialTable.rootPaths ?? []).map((rootPath: any) => {
        return {
          rootPath: rootPath,
        };
      }),
      columns: (initialTable.columns ?? []).map((column: IAPIColumn) => {
        return {
          columnName: column.name ?? "",
          path: column.path ?? "",
          dataType: column.dataType,
          primaryKey: column.isKey,
        };
      }),
      filters: initialTable.filters ?? [],
      useQuerySlicer: initialTable.useQuerySlicer,
      pseudoColumns: (initialTable.pseudoColumns ?? []).map(
        (pcol: IAPIPseudoColumn) => {
          return {
            dataType: pcol.dataType,
            pseudoColumnName: pcol.name ?? "",
            outputColumn: pcol.isMirrored,
            required: pcol.isRequired,
          };
        },
      ),
    };
  }

  function handlePerRowColumn() {
    const perRowColumns = getPerRowValue(apiSettings.tableSettings.requestUrl);
    if (perRowColumns.size === 0) {
      setPerRowColumnNotFound([]);
      return;
    }

    const perRowColumnFound: string[] = [];

    perRowColumns.forEach((perRowColumn) => {
      const foundInColumns = apiSettings.tableSettings.columns.some(
        (column) => column.columnName === perRowColumn,
      );
      const foundInPseudoColumns =
        apiSettings.tableSettings.pseudoColumns?.some(
          (pseudoColumn) => pseudoColumn.pseudoColumnName === perRowColumn,
        );

      if (foundInColumns || foundInPseudoColumns) {
        perRowColumnFound.push(perRowColumn);
      }
    });

    if (perRowColumns.size !== perRowColumnFound.length) {
      const perRowColumnNotFound = Array.from(perRowColumns).filter(
        (perRowColumn) => !perRowColumnFound.includes(perRowColumn),
      );
      setPerRowColumnNotFound(perRowColumnNotFound);
    } else {
      setPerRowColumnNotFound([]);
    }
  }

  useEffect(() => {
    handlePerRowColumn();
    // eslint-disable-next-line
  }, [
    apiSettings.tableSettings.columns,
    apiSettings.tableSettings.pseudoColumns,
    apiSettings.tableSettings.requestUrl,
  ]);

  const filterNonUniquePaths = (columnCollection: Column[]) => {
    const uniqueColumns: Column[] = [];
    const columnSet = new Set();

    for (const item of columnCollection) {
      if (!columnSet.has(item.path)) {
        columnSet.add(item.path);
        uniqueColumns.push(item);
      }
    }
    return uniqueColumns;
  };

  const mapType = (generalType: string) => {
    switch (generalType) {
      case JsonValueType.String:
        return DataType.VARCHAR;
      case JsonValueType.Number:
        return DataType.NUMERIC;
      case JsonValueType.Boolean:
        return DataType.BOOLEAN;
      case JsonValueType.Object:
        return DataType.VARCHAR;
      case JsonValueType.Array:
        return DataType.VARCHAR;
      default:
        return DataType.VARCHAR;
    }
  };

  async function handleValidSubmit() {
    configureTableRef.current?.submitForm();
    tableDataRef.current?.submitRootPaths();
    tableDataRef.current?.submitColumns();
    tableDataRef.current?.submitPseudoColumns();
    // Just here to trigger validation for all fields the user has not touched yet.
    filtersRef.current?.submitForm();
    parameterRef.current?.submitForm();
    headersRef.current?.submitForm();

    const parameterValidationErrors =
      await parameterRef.current?.validateForm();

    // If the user is missing a name or value for the parameters then block them from saving.
    if (!isEmpty(parameterValidationErrors)) {
      ToastrError(
        "Failed to save table",
        "An error occurred saving the table Parameters, please check the Parameters tab for details.",
      );
      setCurrentTab("2");
      scrollToFirstValidationError();
    }

    const pseudoColValidationErrors =
      await tableDataRef.current?.validatePseudoColumnForm();

    // If the user is missing a required values in pseudo column form, block them from saving.
    if (!isEmpty(pseudoColValidationErrors)) {
      ToastrError(
        "Failed to save table",
        "An error occurred saving the Pseudo Columns, please check the Pseudo Columns tab for details.",
      );
      setCurrentTab("1");
      tableDataRef.current?.updateTableDataTab("2");
      scrollToFirstValidationError();
      return;
    }

    const headerValidationErrors = await headersRef.current?.validateForm();

    // If the user is missing a name or value for the headers then block them from saving.
    if (!isEmpty(headerValidationErrors)) {
      ToastrError(
        "Failed to save table",
        "An error occurred saving the table Headers, please check the Headers tab for details.",
      );
      setCurrentTab("3");
      scrollToFirstValidationError();
      return;
    }

    let requiredFieldsEmpty = false;
    const queryParams = tableSettings.queryParams;

    for (let index = 0; index < queryParams.length; index++) {
      if (queryParams[index].name === "" || queryParams[index].value === "") {
        requiredFieldsEmpty = true;
        break;
      }
    }

    if (!requiredFieldsEmpty) updateOrCreateTable();
  }

  function updateOrCreateTable() {
    const submittedTable = mapTable(tableSettings);

    tablePageType === TablePageType.editTable
      ? updateTable(submittedTable)
      : createTable(submittedTable);
  }

  async function updateTable(submittedTable: IAPITable) {
    const tableIndexToUpdate = props.tables.findIndex(
      (table: IAPITable) => table.name === props.selectedTableName,
    );
    const newTables = [...props.tables];
    newTables[tableIndexToUpdate] = createDeepCopy(submittedTable);
    await saveApiConnection(newTables, submittedTable);
  }

  async function createTable(submittedTable: IAPITable) {
    const newTables = [...props.tables];
    newTables.push(submittedTable);
    await saveApiConnection(newTables, submittedTable);
  }

  async function saveApiConnection(
    newTables: IAPITable[],
    submittedTable: IAPITable,
  ) {
    const data = mapConnectionSubmissionData(
      props.globalSettings,
      newTables,
      props.permissions,
      driverList,
    );
    setProcessingRequest(true);

    const { status, error } = await props.callAPI(
      RequestType.Put,
      "/account/updateConnection",
      "",
      data,
    );
    if (status === 200) {
      props.setTables(newTables);
      handlePostSaveTable(submittedTable);
      ToastrSuccess(
        "Table successfully saved",
        `The table "${submittedTable.name}" has been saved in your API connection.`,
      );
      setChameleonId("save-success");
    } else {
      ToastrError("Failed to save table", error.message);
    }
    setProcessingRequest(false);
  }

  function handlePostSaveTable(submittedTable: IAPITable) {
    setUnsavedTableChanges(false);
    setInitialTableSettings(tableSettings);
    setTablePageType(TablePageType.editTable);
    // Allow the change settings warning banner to appear again.
    setHideShowChangeSettingsWarning(false);
    props.setSelectedTableName(submittedTable.name);
    props.setUnsavedGlobalChanges(false);

    // Reset the forms so, if the user changes the form fields, the unsavedTableChanges boolean is set correctly.
    filtersRef.current?.setTouched({});
    parameterRef.current?.setTouched({});
    headersRef.current?.setTouched({});
  }

  function handleBackButton() {
    if (unsavedTableChanges) {
      unsavedChangesPromptModal();
    } else {
      props.setIsSetTablePageDisplayed(false);
    }
  }

  function unsavedChangesPromptModal() {
    const message =
      "If you leave this page, any unsaved changes will be lost. Are you sure you want to leave?";
    const modal = {
      title: "Unsaved Changes",
      body: <div>{message}</div>,
      secondaryButton: (
        <Button
          color="dark"
          onClick={() => {
            modalContext.toggleModal();
            document.body.className = "";
            document.body.style.overflow = "auto";
          }}
        >
          Cancel
        </Button>
      ),
      primaryButton: (
        <Button
          color="danger"
          onClick={() => {
            props.setIsSetTablePageDisplayed(false);
            modalContext.toggleModal();
          }}
        >
          Leave Page
        </Button>
      ),
      displayToggleCloseButton: true,
      displayed: true,
      modalSize: "lg",
    } as IModalProps;
    modalContext.setModal(modal);
  }

  function renderSetTableCard() {
    const docUrl = `${baseDocsAddress}APICreateTable.html`;
    function globalSettingsFlyoutClick() {
      setGlobalFlyoutDisplayed(true);
      dispatch(hideFlyout());
    }

    function documentationFlyoutClick() {
      setGlobalFlyoutDisplayed(false);
      dispatch(showFlyout(docUrl, name, "Connection"));
    }

    return (
      <>
        <Row>
          <Col className="mb-4">
            <Button
              onClick={() => {
                handleBackButton();
              }}
              className="left-arrow-add-table"
            >
              <i className="fa fa-arrow-left fa-lg back-arrow" />
            </Button>
            <h1 className="h3 d-inline align-middle h3 TablePageTitle">
              {tablePageType === TablePageType.addTable ? "Add " : "Edit "}{" "}
              Table
            </h1>
          </Col>
          <Col className="text-end">
            <TertiaryButton
              className="no-focus me-2"
              onClick={() => globalSettingsFlyoutClick()}
              data-testid="button-global-settings"
            >
              <i className="fa fa-solid fa-gear me-1" />
              Global Settings
            </TertiaryButton>
            <span id="previewButton" className="tooltip-padding">
              <Button
                color="secondary"
                className="no-focus me-2"
                data-testid="button-add-table-preview"
                disabled={unsavedTableChanges}
                onClick={() => setIsTablePreviewOpen(true)}
              >
                <i className="fa fa-eye align-middle mb-1 me-2 no-pointer-event" />
                Preview
              </Button>
            </span>
            <UncontrolledTooltip
              placement="top"
              target="previewButton"
              trigger="hover"
              className={unsavedTableChanges ? "" : "d-none"}
            >
              Saving changes is required in order to preview your table.
            </UncontrolledTooltip>
            <Button
              id={chameleonId}
              color="primary"
              className="float-end save-btn"
              data-testid="button-add-table-save"
              disabled={hasPerRowRequestError(tableSettings.requestUrl)}
              onClick={handleValidSubmit}
            >
              <i className="fa fa-save align-middle add-connection-icon no-pointer-event" />
              Save
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <ConfigureTableCard
              apiSettings={apiSettings}
              showChangeSettingsWarning={showChangeSettingsWarning}
              setShowChangeSettingsWarning={(showWarning) =>
                setHideShowChangeSettingsWarning(!showWarning)
              }
              // Clear out the user's inputs if they change the URL, they may have
              // added, renamed, or removed the query slicer field
              onChangeRequestUrl={() => setQueryInputs([])}
              formRef={configureTableRef}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TabWrapper
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              tabs={[
                {
                  tabName: "Table Data",
                  tabEnum: "1",
                  tabComponent: (
                    <TableDataCard
                      apiSettings={apiSettings}
                      openWizard={() => setIsTableSetupWizardOpen(true)}
                      isTableFirstLoad={isTableFirstLoad}
                      perRowColumnNotFound={perRowColumnNotFound}
                      ref={tableDataRef}
                    />
                  ),
                },
                {
                  tabName: "Parameters",
                  tabEnum: "2",
                  tabComponent: (
                    <ParametersCard
                      apiSettings={apiSettings}
                      formRef={parameterRef}
                    />
                  ),
                },
                {
                  tabName: "Headers",
                  tabEnum: "3",
                  tabComponent: (
                    <TableHeadersCard
                      apiSettings={apiSettings}
                      isTableFirstLoad={isTableFirstLoad}
                      formRef={headersRef}
                    />
                  ),
                },
                {
                  tabName: "Filters",
                  tabEnum: "4",
                  tabComponent: (
                    <FiltersCard
                      apiSettings={apiSettings}
                      formRef={filtersRef}
                    />
                  ),
                },
              ]}
              extraButton={
                <>
                  <Col className="float-end-view-docs-btn">
                    <ViewDocumentationButton
                      openFlyout={documentationFlyoutClick}
                    />
                  </Col>
                </>
              }
            />
          </Col>
        </Row>
      </>
    );
  }

  const contents = loading ? (
    <Loader />
  ) : (
    <Row>
      <Col>{renderSetTableCard()}</Col>
      {isGlobalFlyoutDisplayed ? (
        <Col className="global-flyout" hidden={flyout.isOpen}>
          <GlobalSettingsFlyout
            apiSettings={apiSettings}
            apiConnectionPageType={props.apiConnectionPageType}
            tables={props.tables}
            permissions={props.permissions}
          />
        </Col>
      ) : (
        <Flyout />
      )}
    </Row>
  );

  return (
    <Container fluid className={`p-0 ${getComponentName()}`}>
      <div hidden={!processingRequest}>
        <div className="loading-background" />
        <Spinner className="spinner-border loading-spinner" color="info" />
      </div>
      <PromptWithModal
        when={unsavedTableChanges}
        navigate={(path: string) => props.router.navigate(path)}
      />
      {contents}

      {isTableSetupWizardOpen && (
        <StepWizardModal
          className="apiConnector-setTable-stepWizard"
          isDisplayed
          showNavigation
          modalSize="xl"
          title="Configure Table"
          toggleModal={() => {
            setIsTableSetupWizardOpen(false);
          }}
        >
          {isPerRowRequest(tableSettings.requestUrl) ? (
            <StepWizardStep stepName={TableWizardStep.QueryInputEntry}>
              <QueryInputIdModal
                queryInputs={queryInputs}
                setQueryInputs={(queryInputs) => {
                  setQueryInputs(queryInputs);
                  // If the user changes the query input in the table setup wizard, make sure
                  // to clear out their root paths and columns.
                  setRootPathSelectionWizard([]);
                  setColumnSelectionWizard([]);
                }}
                isResponseValid={axiosResult.status === 200}
                tableSettings={tableSettings}
              />
            </StepWizardStep>
          ) : null}
          <StepWizardStep stepName={TableWizardStep.PreviewRequest}>
            <PreviewRequestStep
              apiSettings={apiSettings}
              axiosResult={axiosResult}
              setAxiosResult={setAxiosResult}
              queryInputs={queryInputs}
            />
          </StepWizardStep>
          <StepWizardStep stepName={TableWizardStep.AddRootPaths}>
            <RootPathsStep
              apiSettings={apiSettings}
              axiosResult={axiosResult}
              queryInputs={queryInputs}
              isCustomRootPaths={isCustomRootPaths}
              setIsCustomRootPaths={setIsCustomRootPaths}
              selectedRootPaths={rootPathSelectionWizard}
              setSelectedRootPaths={(selectedRootPaths) => {
                setRootPathSelectionWizard(selectedRootPaths);
              }}
              setSelectedColumns={setColumnSelectionWizard}
            />
          </StepWizardStep>
          <StepWizardStep stepName={TableWizardStep.AddColumns}>
            <AddColumnsStepV2
              apiSettings={apiSettings}
              axiosResult={axiosResult}
              queryInputs={queryInputs}
              selectedRootPaths={rootPathSelectionWizard}
              selectedColumns={columnSelectionWizard}
              setSelectedColumns={setColumnSelectionWizard}
            />
          </StepWizardStep>
          <StepWizardStep stepName={TableWizardStep.PreviewTable}>
            <PreviewTableStep
              apiSettings={apiSettings}
              queryInputs={queryInputs}
              selectedRootPaths={rootPathSelectionWizard}
              selectedColumns={columnSelectionWizard}
              onSaveChanges={() => {
                saveWizardChanges();
                setIsTableSetupWizardOpen(false);
              }}
            />
          </StepWizardStep>
        </StepWizardModal>
      )}

      {isTablePreviewOpen && (
        <StepWizardModal
          className="apiConnector-setTable-stepWizard"
          isDisplayed
          showNavigation={false}
          modalSize="xl"
          title="Preview Table"
          toggleModal={() => {
            setIsTablePreviewOpen(false);
          }}
        >
          {isPerRowRequest(tableSettings.requestUrl) ? (
            <StepWizardStep stepName={TablePreviewStep.PostSaveQueryInputEntry}>
              <QueryInputIdModal
                queryInputs={queryInputs}
                setQueryInputs={setQueryInputs}
                isResponseValid={axiosResult.status === 200}
                tableSettings={tableSettings}
              />
            </StepWizardStep>
          ) : null}

          <StepWizardStep stepName={TablePreviewStep.PostSaveQueryInputEntry}>
            <PostSavePreviewTable
              apiSettings={apiSettings}
              queryInputs={queryInputs}
              tableSettings={tableSettings}
              onClose={() => setIsTablePreviewOpen(false)}
            />
          </StepWizardStep>
        </StepWizardModal>
      )}
    </Container>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    hideFlyout: () => dispatch(hideFlyout()),
    showFlyout: (docUrl: any, name: any, source: any) =>
      dispatch(showFlyout(docUrl, name, source)),
  };
};

export default connect(
  (store: any) => ({
    app: store.app,
    flyout: store.flyout,
    sidebar: store.sidebar,
  }),
  mapDispatchToProps,
)(withAPI(withRouter(SetTable)));
