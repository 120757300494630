import { useContext, useEffect, useMemo, useState } from "react";
import { TableWizardStep } from "../../../types/TableWizardStep";
import { Column, QueryInput } from "../../../SetTable";
import { ToastrError } from "../../../../../../services/toastrService";
import { useAPI } from "../../../../../../components/useAPI";
import { mapAPIConnectorProps } from "../../../../ApiConnectorFactory";
import { mapTable } from "../../../SetTableFactory";
import {
  IAPIResponse,
  RequestType,
} from "../../../../../../components/withAPI";
import {
  APITableResponseType,
  DataType,
  IAPIConnectorProps,
} from "../../../../../../models";
import { IAPIColumnsRequest } from "../../../../../../models/Drivers/IAPIColumnsRequest";
import Loader from "../../../../../../components/Loader";
import { InfoIcon } from "../../../../../../components/InfoIcon";
import {
  CheckboxTree,
  ICheckboxTreeRow,
} from "../../../../../../components/tables/CheckboxTree";
import {
  XMLTreeTableRow,
  XMLValueType,
} from "../../ParsingUtilities/XML/parseXMLAsTreeTable";
import { JSONTreeTableRow } from "../../ParsingUtilities/JSON/parseJSONAsTreeTable";
import { parseResponseAsTreeTable } from "../../ParsingUtilities/parseResponseAsTreeTable";
import { last, trimStart } from "lodash";
import {
  AddColumnsNode,
  findTreeTableRowForColumnNode,
  IAddColumnsTreeNode,
} from "./AddColumnsNode";
import { replaceAPITableSettingsURL } from "../../../replaceAPITableSettingsURL";
import { IAPIConnectorSettings } from "../../../types/IAPIConnectorSettings";
import { IAPIPossibleColumn } from "../../../../../../models/APIConnector/IAPIPossibleColumn";
import { IStepBodyProps } from "../../../../../../components/wizard/components/StepWizardStep";
import { StepWizardStepFooter } from "../../../../../../components/wizard/components/StepWizardStepFooter";
import {
  ButtonType,
  CDataButton,
} from "../../../../../../components/buttons/CDataButton";
import { StepWizardContext } from "../../../../../../components/wizard/components/StepWizardContext";

export interface IAddColumnsStepProps extends IStepBodyProps {
  apiSettings: IAPIConnectorSettings;

  axiosResult: IAPIResponse;

  queryInputs: QueryInput[];

  selectedRootPaths: string[];

  selectedColumns: Column[];
  setSelectedColumns: (columns: Column[]) => void;
}

export const AddColumnsStepV2 = (props: IAddColumnsStepProps) => {
  const {
    axiosResult,
    apiSettings: { tableSettings, globalSettings },
    queryInputs,
    nextStep,
    goToNamedStep,
    previousStep,
    currentStep,
    selectedRootPaths,
    selectedColumns,
    setSelectedColumns,
  } = props;

  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [columnsResponse, setColumnsResponse] = useState<IAPIPossibleColumn[]>(
    [],
  );

  const stepperContext = useContext(StepWizardContext);

  /** A parsed response from the API the user is trying to talk to, used to display icons and default the data type for a column. */
  const treeTable: XMLTreeTableRow[] | JSONTreeTableRow[] | Error =
    useMemo(() => {
      const parsedResponse = parseResponseAsTreeTable(
        tableSettings,
        axiosResult,
      );

      return parsedResponse;
    }, [tableSettings, axiosResult]);

  const api = useAPI();

  async function getAPIColumns() {
    const tableSettingsWithInputId = replaceAPITableSettingsURL(
      tableSettings,
      queryInputs,
    );
    const apiConnectorProps: IAPIConnectorProps = mapAPIConnectorProps(
      globalSettings,
      [mapTable(tableSettingsWithInputId)],
    );
    const rootPaths: IAPIColumnsRequest["rootPaths"] = {};

    for (const rootPath of selectedRootPaths) {
      rootPaths[rootPath] = rootPath;
    }

    const apiColumnsParams: IAPIColumnsRequest = {
      apiConnectorProps,
      rootPaths,
    };

    const { status, payload } = await api.callAPI<IAPIPossibleColumn[]>(
      RequestType.Post,
      "/getAPIColumns",
      "Error loading API columns",
      apiColumnsParams,
    );
    if (status === 200) {
      if (payload == null || payload.length === 0) {
        ToastrError(
          "No columns detected.",
          "No columns were found using the selected repeat elements, please double check your repeat elements.",
        );
        previousStep!();
      }
      setColumnsResponse(payload ?? []);
    }
    setLoading(false);
  }

  useEffect(() => {
    getAPIColumns();
  }, []); // eslint-disable-line

  function onNext() {
    if (selectedColumns.length === 0) {
      ToastrError("Columns not selected", "Select columns to continue");
    } else {
      nextStep!();
    }
  }

  useEffect(() => {
    // If we can't parse the API response for any reason, then send the
    // user back to the previous step and give the a toast error.
    //
    // This should have already happened in the RootPathsStep so this is just
    // here as a sanity check.
    if (treeTable instanceof Error) {
      goToNamedStep!(TableWizardStep.PreviewRequest);
      ToastrError("Failed to parse response", treeTable.message);
    }
  }, [treeTable]); // eslint-disable-line

  if (treeTable instanceof Error) {
    return null;
  }

  let rows = buildColumnTree(columnsResponse).map((node) =>
    toCheckboxTreeRow(node, treeTable),
  );

  if (searchText.trim().length > 0) {
    const trimmedSearch = searchText.trim().toLowerCase();
    // If the user types something in the search, filter by both displayed text lines for our rows.
    rows = rows.filter(
      (r) =>
        r.data.columnDisplayName.toLowerCase().includes(trimmedSearch) ||
        r.data.xpathDisplayName.toLowerCase().includes(trimmedSearch),
    );
  }

  return (
    <div className="pages-apiConnector-components-AddColumnsStep">
      <>
        {loading && <Loader />}
        {!loading && (
          <>
            <div className="instructionsText">
              Select the columns from the list below that you want added to your
              API table.
            </div>
            <div>
              <i className="fa-regular fa-magnifying-glass align-middle me-2 search-glass" />
              <input
                className="border shadow rounded search-bar"
                type="text"
                key="filter-tree"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search..."
                aria-label="Search"
              />
            </div>
            <CheckboxTree
              canCollapse={false}
              title={
                <div className="tooltip-label">
                  <label className="columns-title">Columns</label>
                  <InfoIcon
                    tooltipMessage="Check this box for any field that you want to be exposed as a column in your API's data model."
                    className="info-icon-col"
                    iconId="info-icon-col"
                  />
                </div>
              }
              rows={rows}
              checkedValues={selectedColumns.map((c) => c.path)}
              setCheckedValues={(checkedXPaths) => {
                stepperContext.setPendingCompletedStepsToCurrentStep(
                  currentStep!,
                );
                // Convert the xpaths to an actual Column object.
                const selectedColumns = xpathsToColumns(
                  rows,
                  checkedXPaths,
                  treeTable,
                  tableSettings.responseType,
                );
                setSelectedColumns(selectedColumns);
              }}
            />
          </>
        )}
      </>
      <StepWizardStepFooter>
        <CDataButton
          buttonType={ButtonType.Tertiary}
          onClick={() => previousStep!()}
        >
          Back
        </CDataButton>
        <CDataButton
          buttonType={ButtonType.Primary}
          onClick={onNext}
          disabled={selectedColumns.length === 0}
        >
          Next
        </CDataButton>
      </StepWizardStepFooter>
    </div>
  );
};

function buildColumnTree(
  columnResponse: IAPIPossibleColumn[],
): IAddColumnsTreeNode[] {
  if (!columnResponse || columnResponse.length === 0) {
    return [];
  }

  const nodes: IAddColumnsTreeNode[] = [];

  // columnName will be a json path or xpath in an object like `first_name` or `person.first_name` or `person/first_name`
  for (const column of columnResponse) {
    // The API response sends down a lot of columns like `person/_id` that the user cannot select
    // Remove them.
    if (column.columnName.match(/.*:_id$/) && column.path.match(/.*\/_id$/)) {
      continue;
    }

    nodes.push({
      xpath: column.path,
      columnName: column.columnName,
      columnDisplayName: last(column.path.replace("XMLData", "/").split("/"))!,
      depth: column.path.split("/").length,
      xpathDisplayName:
        column.path.length > 1 ? trimStart(column.path, "/") : column.path,
    });
  }

  return nodes;
}

/** Converts a column xpath node from the tree into a node for the `<CheckboxTree />` component */
function toCheckboxTreeRow(
  node: IAddColumnsTreeNode,
  treeTable: XMLTreeTableRow[] | JSONTreeTableRow[],
): ICheckboxTreeRow<IAddColumnsTreeNode> {
  return {
    data: node,
    // The columns are flat and have no children.
    childRows: [],
    getValue: (data) => {
      return data.xpath;
    },
    render: (data) => {
      return <AddColumnsNode node={data} treeTable={treeTable} />;
    },
  };
}

/**
 * Convert the xpaths that the user checks in the tree to Column objects.
 * We default the name and data type here but the user can change them once they finish the wizard.
 */
function xpathsToColumns(
  allTreeNodes: ICheckboxTreeRow<IAddColumnsTreeNode>[],
  selectedXPaths: string[],
  treeTable: XMLTreeTableRow[] | JSONTreeTableRow[],
  responseType: APITableResponseType,
): Column[] {
  const selectedNodes = selectedXPaths.map(
    (xpath) => allTreeNodes.find((t) => t.data.xpath === xpath)!,
  );

  return selectedNodes.map((node) => {
    const matchingTreeTableRow = findTreeTableRowForColumnNode(
      node.data,
      treeTable,
    );

    let dataType = DataType.VARCHAR;

    if (matchingTreeTableRow != null) {
      dataType =
        treeTableTypeToColumnDataType[matchingTreeTableRow.type] ??
        DataType.VARCHAR;
    }

    let columnName = node.data.columnName.replaceAll(".", "_");

    // For a customer's XML API, the generated column names from the driver
    // are extremely long.
    // Split them on a dot instead of using the name the driver gives us.
    if (responseType === APITableResponseType.XML) {
      columnName = last(node.data.columnName.split("."))!;
    }

    const column: Column = {
      primaryKey: false,
      columnName: columnName,
      driverColumnName: node.data.columnName,
      dataType,
      path: node.data.xpath,
    };

    return column;
  });
}

const treeTableTypeToColumnDataType: Record<XMLValueType, DataType | null> = {
  array: DataType.VARCHAR,
  null: DataType.VARCHAR,
  object: DataType.VARCHAR,
  boolean: DataType.BOOLEAN,
  number: DataType.NUMERIC,
  string: DataType.VARCHAR,
};
