import {
  APIAuthType,
  APIPaginationType,
  ConnectionType,
  IAPIAuth,
  IAPIConnectorProps,
  IAPIPagination,
  IAPITable,
  IDriverList,
  UserRole,
} from "../../models";
import { IGlobalSettings, KeyValueTableRow } from "./ApiConnector";
import { IFullPermission } from "../connections/components/PermissionsCard";
import { GLOBAL_SETTINGS_CONSTANTS as Constants } from "./ApiConnector.constants";
import { store } from "../../redux/store";
import { IConnectionArgs } from "src/bffmodels/Connection/IConnectionArgs";

export function mapConnectionSubmissionData(
  globalSettings: IGlobalSettings,
  tables: IAPITable[],
  permissions: IFullPermission[],
  drivers: IDriverList,
): IConnectionArgs {
  const apiConnectorProps = mapAPIConnectorProps(globalSettings, tables);
  return {
    name: globalSettings.connectionName,
    connectionId: globalSettings.connectionID,
    connectionType: ConnectionType.Shared,
    apiConnectorProps: apiConnectorProps,
    hasUserConnectionString: false,
    driver: "REST",
    permissions: permissions.map((permission) => ({
      UserId: permission.id,
      OpsAllowed: permission.opsAllowed,
    })),
    userId: store.getState().user.id,
    connectionString: "",
    driverVersion:
      drivers.drivers?.find((d) => d.driver === "REST")?.version ?? "",
    oAuthProps: {},
    props: {},
    userDefinedProps: {},
    userRole: UserRole.Admin,
    walletFileContent: "",
  };
}

export function mapAPIConnectorProps(
  globalSettings: IGlobalSettings,
  tables: IAPITable[],
): IAPIConnectorProps {
  const trimmedAuthentication = removeUnusedAuthParameters(
    globalSettings.authentication,
  );
  const trimmedPagination = removeUnusedPaginationParameters(
    globalSettings.pagination,
  );
  const parsedHeaders = parseKeyValuePairsFromTableRows(globalSettings.headers);
  const parsedParameters = parseKeyValuePairsFromTableRows(
    globalSettings.queryParams,
  );

  return {
    authentication: trimmedAuthentication,
    advanced: globalSettings.advanced,
    headers: parsedHeaders,
    pagination: trimmedPagination,
    queryParams: parsedParameters,
    tables: tables,
  };
}

function removeUnusedAuthParameters(authentication: IAPIAuth) {
  const trimmedAuth = { ...authentication };
  const oAuthParams = [
    Constants.OAUTH_CLIENT_ID,
    Constants.OAUTH_CLIENT_SECRET,
    Constants.OAUTH_REQUEST_TOKEN_URL,
    Constants.OAUTH_AUTHORIZATION_URL,
    Constants.OAUTH_ACCESS_TOKEN_URL,
    Constants.OAUTH_REFRESH_TOKEN_URL,
    Constants.OAUTH_PARAMS,
    Constants.OAUTH_SCOPES,
    Constants.AUTHORIZATION_HEADER_PREFIX,
  ];
  const userPassParams = [Constants.USER, Constants.PASSWORD];

  switch (trimmedAuth.type) {
    case APIAuthType.None: {
      removeParameters(trimmedAuth, [...userPassParams, ...oAuthParams]);
      break;
    }
    case APIAuthType.Basic:
    case APIAuthType.Digest: {
      removeParameters(trimmedAuth, oAuthParams);
      break;
    }
    case APIAuthType.OAuth1: {
      removeParameters(trimmedAuth, [
        ...userPassParams,
        Constants.OAUTH_REFRESH_TOKEN_URL,
      ]);
      break;
    }
    case APIAuthType.OAuth2: {
      removeParameters(trimmedAuth, [
        ...userPassParams,
        Constants.OAUTH_REQUEST_TOKEN_URL,
      ]);
      break;
    }
  }
  return trimmedAuth;
}

function removeUnusedPaginationParameters(pagination: IAPIPagination) {
  const trimmedPagination = { ...pagination };

  const offsetPageNumberParams = [
    Constants.PAGE_SIZE_PARAM,
    Constants.PAGE_SIZE,
    Constants.PAGE_NUMBER_PARAM,
    Constants.OFFSET_PARAM,
    Constants.OFFSET_START,
  ];
  const tokenParams = [
    Constants.TOKEN_PATH,
    Constants.HAS_MORE_PATH,
    Constants.REQUEST_PARAM,
    Constants.REQUEST_PATH,
  ];
  const urlParams = [Constants.URL_HEADER, Constants.URL_PATH];

  switch (trimmedPagination.type) {
    case APIPaginationType.None: {
      removeParameters(trimmedPagination, [
        ...offsetPageNumberParams,
        ...tokenParams,
        ...urlParams,
      ]);
      break;
    }
    case APIPaginationType.Offset: {
      removeParameters(trimmedPagination, [
        ...urlParams,
        ...tokenParams,
        Constants.PAGE_NUMBER_PARAM,
      ]);
      break;
    }
    case APIPaginationType.PageNumber: {
      removeParameters(trimmedPagination, [
        ...urlParams,
        ...tokenParams,
        Constants.OFFSET_PARAM,
      ]);
      break;
    }
    case APIPaginationType.PageToken: {
      removeParameters(trimmedPagination, [
        ...offsetPageNumberParams,
        ...urlParams,
      ]);
      break;
    }
    case APIPaginationType.URL: {
      removeParameters(trimmedPagination, [
        ...offsetPageNumberParams,
        ...tokenParams,
      ]);
      break;
    }
  }
  return trimmedPagination;
}

function removeParameters(object: Record<string, any>, properties: string[]) {
  properties.forEach((property) => {
    property === Constants.PAGE_SIZE
      ? (object[property] = 100)
      : property === Constants.AUTHORIZATION_HEADER_PREFIX
        ? (object[property] = "Bearer")
        : (object[property] = "");
  });
}

export function parseKeyValuePairsFromTableRows(
  connectorProps: KeyValueTableRow[],
) {
  const parsedPairs = connectorProps.reduce((obj: any, item: any) => {
    if (item.name && item.value && !obj[item.name]) {
      return { ...obj, [item.name]: item.value };
    } else {
      return obj;
    }
  }, {});

  // Check for empty headers or parameters after save, if empty then return empty inputs.
  if (Object.keys(parsedPairs).length > 0) {
    return parsedPairs;
  } else {
    return {};
  }
}
